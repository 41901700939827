import {Button, Grid, Link} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";

export const getTelegramAuth = () => {
    return axios.get('https://gandhi.camp/api/v1/users/login-code/', {
        headers: {
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', // Not always effective, depends on the server configuration
        }
    })
}
const Notifiaction = ({name, action}: { name: string, action: string }) => {
    return <Grid container flexDirection={'row'} justifyContent={'flex-start'}
                 className={'p-2  create-camp border-solid border-yellowPrimary border rounded-[19px] w-full mt-4'}>
        <Grid item xs={3}><img src={'icons/ghandhi.svg'} alt={'gma'}/></Grid>
        <Grid item xs={9}><Grid container flexDirection={'column'}>
            <div className={''}>
                <p className={'leading-3 text-xs inline text-white leading-2'}>{name}</p>
                <Link className={'leading-3 inline cursor-pointer text-xs mx-2 leading-2'}>name</Link>
                <p className={'inline leading-3 text-xs text-white leading-2'}>{action}</p>
                <Link className={'inline leading-3	cursor-pointer text-xs mx-2 leading-2'}>action</Link>
            </div>
        </Grid></Grid>
    </Grid>
}

const fetchMe = (bearer: string) => {
    return axios.get('https://gandhi.camp/api/v1/users/me/', {
        headers: {
            'Content-Type': 'application/json', 'Gcauth': `Bearer ${bearer}`
        }
    })
}

const Header = () => {
    const [open, setOpen] = useState(false);
    const [stopPolling, setStopPolling] = useState<boolean>(false);
    const [access, setAccess] = useState<string>('');
    const {data, isLoading: isLoadingLoginCode} = useQuery({
        queryKey: ['login-code'], queryFn: () => axios.get('https://gandhi.camp/api/v1/users/login-code/')
    })

//     const { data: dataMe, error, isLoading } = useQuery({
//         queryKey: ['test', stopPolling],
//         queryFn: () => fetchMe(stopPolling), // Query function
//     {
//         enabled: stopPolling
//     } // Condition to trigger the query
// });
    const {data: loginData} = data || {};
    const {tgLoginUrl, code, tgLoginQr} = loginData || {};

    useEffect(() => {
        if (loginData && !stopPolling) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await axios.post('https://gandhi.camp/api/v1/users/login/', {'code': code});
                    const {data} = response || {};
                    const {access} = data || {};
                    if (access) {
                        setStopPolling(true); // Set stopPolling to true
                        setAccess(access); // Set stopPolling to true
                    }
                } catch (error) {
                    console.error('Error during polling:', error);
                }
            }, 3000);

            return () => clearInterval(intervalId);
        }
    }, [loginData, code, stopPolling]);

    const {data: dataMe} = useQuery({
        queryKey: ['me'], queryFn: () => fetchMe(access), enabled: Boolean(stopPolling)
    })

    const {data: userData} = dataMe || {};
    const {username, telegramId, image} = userData || {};



    return <Grid container justifyContent={'center'} className={'p-4 h-40'} alignItems={'center'}>
        <Grid item className={'fixed left-5 top-5'}>
            {dataMe ? <><Button
                onClick={() => window.open(tgLoginUrl)}
                disabled={isLoadingLoginCode}
                className={'hover:border-yellowPrimary border border-transparent border-solid color-white p-7 bg-[#ffffff08] mr-4 hover:bg-[#FFFFFF12] rounded-[28px] h-full'}>
                <Grid container spacing={1} flexDirection={'column'} className={''} alignItems={'center'}>
                    <Grid item><img src={image ? image : '/icons/username.svg'} alt={'profile'}/></Grid>
                </Grid>
            </Button>
                <p className={'normal-case text-yellowPrimary'}>id: {telegramId}</p></> : <><Button
                onClick={() => window.open(tgLoginUrl)}
                disabled={isLoadingLoginCode}
                className={'z-10 hover:border-yellowPrimary border border-transparent border-solid color-white px-7 py-5 bg-[#ffffff08] mr-4 hover:bg-[#FFFFFF12] rounded-[28px] h-full'}>
                <Grid container spacing={1} flexDirection={'column'} className={''} alignItems={'center'}>
                    <Grid item><img src={'/icons/profile.svg'} alt={'profile'}/></Grid>
                    <Grid item><p className={'normal-case text-yellowPrimary'}>Log in</p></Grid>
                </Grid>
            </Button>
                <Button
                    className={"border px-7 py-5 bg-transparent rounded-[28px] border-solid border-yellowPrimary create-camp"}>
                    <Grid container spacing={1} flexDirection={'column'} alignItems={'center'} className={''}>
                        <Grid item><img src={'/icons/plus.svg'} alt={"plus"}/></Grid>
                        <Grid item><p
                            className={'text-yellowPrimary leading-4 normal-case font-raleway'}>Create <br/> Camp
                        </p></Grid>
                    </Grid>
                </Button></>}
        </Grid>
        <Grid item className={''}><img src={"/icons/logo.svg"} alt={'logo'}/></Grid>
        <Grid item xs={5} className={'fixed right-5 top-5'}>
            <Button onClick={() => setOpen(!open)}
                    className={'bg-[#ffffff07] hover:bg-[#FFFFFF12] rounded-[14px] h-[50px] w-full cursor-pointer px-3'}>
                <Grid container spacing={2} flexDirection={'row'} justifyContent={'space-between'}
                      alignItems={'center'}>
                    <Grid item><img src={"/icons/alarm.svg"} alt={'alarm'}/></Grid>
                    <Grid item><p className={'text-white normal-case'}>Notification bar</p></Grid>
                    <Grid item className={'mr-1'}><KeyboardArrowDownIcon className={'text-white'}/></Grid>
                </Grid>
            </Button>
            <Grid container flexDirection={'column'} className={'absolute w-full'} spacing={4}>
                {open && [{name: 'dsadsa dsadsa', action: 'dsadas dsadas'}, {
                    name: "wwwdsada swwwwds adasw", action: 'dsadcccc ccccccccds adccccccc ccccc'
                }, {
                    name: 'dsa ddsad', action: 'dwsc xzcxzc xdwscxzc xzcx'
                }].map((e, index) => {
                    return <Grid key={index} item><Notifiaction name={e.name} action={e.action}/></Grid>
                })}
            </Grid>


        </Grid>
    </Grid>
}

export default Header;