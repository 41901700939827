import {Grid} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import React, {ReactNode} from "react";

const Layout = ({page}: { page: ReactNode }) => {
    return <Grid className={'root1'}>
        <Grid className={'root'}>
            <Header/>
            {page}
            <Footer/>
        </Grid>
    </Grid>
}

export default Layout;